import Masonry from "masonry-layout";

export default function gridMasonry() {
    var gridMasonry = document.querySelector('.grid-masonry');
    if (gridMasonry != null) {
        var msnry = new Masonry(gridMasonry, {
            // options
            itemSelector: '.grid-masonry-item',
            columnWidth: '.grid-sizer',
            percentPosition: true
        });
    }
}
