import Swiper from "swiper";
import $ from "jquery";

export default function statsSwiper() {
    let statsSwiper = new Swiper('#stats-swiper', {
        slidesPerView: 'auto',
        freeMode: true,
    });

    statsSwiper.on('progress', function(swiper, progress) {
        let $el = $(swiper.el);
        if (progress <= 0) {
            $el.removeClass('mask-beginning');
            $el.addClass('mask-end'); // account for first load
            $('.swiper-custom-navigation .swiper-prev').addClass('disabled');
        } else if (progress >= 1) {
            $el.removeClass('mask-end');
            $('.swiper-custom-navigation .swiper-next').addClass('disabled');
        } else {
            $('.swiper-custom-navigation .swiper-prev').removeClass('disabled');
            $('.swiper-custom-navigation .swiper-next').removeClass('disabled');
            $el.addClass('mask-beginning mask-end');
        }
    });

    $('.swiper-custom-navigation a').on('click', function() {
        let forward = !$(this).hasClass('swiper-prev');

        let nextTranslate = statsSwiper.getTranslate();
        if (forward) {
            nextTranslate -= 300;
        } else {
            nextTranslate += 300;
        }

        statsSwiper.translateTo(nextTranslate, 500);

        return false;
    });
}
