import Cookies from 'js-cookie';

export default function cookiesBanner() {
    if (Cookies.get('cookieBanner') === undefined) {
        $('#cookiesBanner').addClass('active');
    }

    $(document).on('click', '#cookiesBanner .close-banner', function() {
        $('#cookiesBanner').removeClass('active');
        Cookies.set('cookieBanner', true, { expires: 31 });

        return false;
    });
}
