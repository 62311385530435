const $ = require("jquery");
export default function header() {
    const $body = $('body');
    const $header = $('#header');
    let scrollDirection = 'down';
    let lastScrollTop = 0;
    let lastWindowWidth = $(window).outerWidth();

    function makeHeaderTiny() {
        $body.addClass('header-tiny');

        let totalHeight = $header.find('.header-top').outerHeight();
        if ($body.hasClass('has-alert')) {
            let alertHeight = $header.find('.alert').outerHeight();
            totalHeight += alertHeight;
        }

        $header.css('top', -totalHeight - 1);

        document.dispatchEvent(new CustomEvent('header-height-change', {
            detail: -totalHeight - 1
        }));
    }

    function resetHeader() {
        $body.removeClass('header-tiny');
        $header.css('top', 0);

        document.dispatchEvent(new CustomEvent('header-height-change', {
            detail: 0
        }));
    }

    function headerOnScrollHandler() {
        const newScrollTop = $(window).scrollTop();
        scrollDirection = newScrollTop > lastScrollTop ? 'down' : 'up';

        const headerTiny = $body.hasClass('header-tiny');
        if (newScrollTop > 150 && scrollDirection === 'down') {
            if (!headerTiny) {
                makeHeaderTiny();
            }
        } else if (headerTiny && scrollDirection === 'up') {
            resetHeader();
        }

        lastScrollTop = newScrollTop;
    }

    function headerStickyResize(windowWidth) {
        if (lastWindowWidth >= 1200 && windowWidth < 1200) { // switch from xl to lg
            resetHeader();
            $(window).off('scroll');
        } else if (lastWindowWidth < 1200 && windowWidth >= 1200) { // switch from lg to xl
            $(window).on('scroll', headerOnScrollHandler);
            $(window).trigger('scroll');
        }
    }

    $('#header .alert').on('closed.bs.alert', function () {
        if ($header.find('.alert').length === 0) {
            $body.removeClass('has-alert');
        }
        $('#wrapper').css('padding-top', $header.outerHeight());
    })

    function resize() {
        let windowWidth = $(window).outerWidth();

        headerStickyResize(windowWidth);
        $('#wrapper').css('padding-top', $header.outerHeight());

        lastWindowWidth = windowWidth;
    }

    $(document).ready(function() {
        if (lastWindowWidth >= 1200) {
            $(window).on('scroll', headerOnScrollHandler);
        }

        resize();
    });

    $(window).on('resize', resize);
}
