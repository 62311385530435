import Popper from "popper.js";

export default function jobsMap() {
    const jobPopoverElement = document.querySelector('.job-popover');
    const jobPopoverContentElement = document.querySelector('.job-popover-content');

    function _clearJobPopover() {
        jobPopoverContentElement.innerHTML = '';
    }

    function _initJobPopover(popoverContent, region) {
        jobPopoverContentElement.innerHTML = popoverContent;
        new Popper(region, jobPopoverElement, {
            placement: 'auto',
            modifiers: {
                preventOverflow: { boundariesElement: document.querySelector('.map') },
            },
        });
    }

    function _showPopover() {
        jobPopoverElement.classList.remove('d-none');

        document.addEventListener("click", _handlePopoverBlur);
    }

    function _hidePopover() {
        jobPopoverElement.classList.add('d-none');

        document.removeEventListener("click", _handlePopoverBlur);
    }

    function _handlePopoverBlur(e) {
        const target = e.target;
        if (target.closest('.job-popover') === null && target.closest('#map') === null) {
            _hidePopover();
        }
    }

    document.querySelectorAll('.map .region[role="button"]').forEach((el) => {
        el.addEventListener('click', (e) => {
            _hidePopover();
            _clearJobPopover();
            _initJobPopover(el.dataset.html, e.target);
            _showPopover();
        });
    });

    if (document.querySelector('.job-popover .btn-close')) {
        document.querySelector('.job-popover .btn-close').addEventListener('click', () => {
            _hidePopover();
        });
    }
}
