import $ from "jquery";

export default function lexicon() {
    /* START lexion */
    function applyLexicon($element, key, value) {
        var full_text = $element.text();
        var full_text_replace = full_text.replace(new RegExp("\\b(" + key + ")\\b","mi"), "<abbr data-toggle=\"tooltip\" data-placement=\"top\" title=\""+ value +"\">$&</abbr>");
        $element.replaceWith(full_text_replace);
    }

    var url = $('section.content-body').data('ajax-lexicon');
    self.xhr = $.getJSON(url, {
    }).done(function(data) {
        $.each(data, function(key, value) {
            $('section.content-body').find('.content-lexicon').find('p').contents().each(function() {
                if (this.nodeType === 3) { // text
                    applyLexicon($(this), key, value);
                } else if (this.nodeName === 'SPAN') {
                    applyLexicon($(this).contents().first(), key, value);
                }
            });
        });

        $('[data-toggle="tooltip"]').tooltip();
    }).fail(function(e) {
        console.log( 'error with lexicon' );
    });
    /* END lexicon */
}
