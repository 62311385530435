'use strict';

const $ = require('jquery');
window.$ = $;
window.jQuery = $;
require('bootstrap');

// import Swiper styles
import 'swiper/swiper-bundle.css';
import '../css/app.scss';

import { submitFormOnChange } from './modules';

import adminMenu from '@elasticms/admin-menu';
import ajaxSearch from "./ajax-search";
import ajaxTables from "./ajax-tables";
import datepicker from "./datepicker";
import form from "./form";
import tableScroll from "./table_scroll";
import cookiesBanner from "./cookiesBanner";
import lexicon from "./modules/lexicon";
import scrollToElement from "./modules/scrollToElement";
import backToTop from "./modules/backToTop";
import externalLinks from "./modules/externalLinks";
import toc from "./modules/toc";
import statsSwiper from "./modules/statsSwiper";
import gridMasonry from "./modules/gridMasonry";
import searchFilters from "./modules/searchFilters";
import header from "./modules/header";
import footer from "./modules/footer";
import breadcrumbResponsive from "./modules/breadcrumb"
import jobsSwiper from "./modules/jobsSwiper";
import jobsMap from "./modules/jobsMap";
import selectToURL from "./modules/selectToURL";
// import chatbot from "./chatbot";

const $body = $('body');
let lastWindowWidth = $(window).outerWidth();
let jsTranslations = $body.data('translations');
const $onlineServices = $('#online-services-list');

ajaxSearch();
adminMenu();
ajaxTables();
tableScroll();
submitFormOnChange();
// chatbot();


function resize() {
    let windowWidth = $(window).outerWidth();

    if ($onlineServices.length) {
        onlineServices(windowWidth);
    }
    breadcrumbResponsive(windowWidth, lastWindowWidth);

    lastWindowWidth = windowWidth;
}

function stickyOrNotSticky($element) {
    if($element.outerHeight() + parseInt($element.css('top')) >= $(window).outerHeight() - $('.navbar').outerHeight()) {
        $element.removeClass('sticky');
    }
}

function onlineServicesToCollapse() {
    $('#online-services-list .list-group-title')
        .removeAttr('tabindex')
        .attr({
            'data-toggle': "collapse",
            'href': "#online-services-list-collapsible",
            'role': "button",
            'aria-expanded': "false",
            'aria-controls': "online-services-list-collapsible"
        });

    $('#online-services-list-collapsible').addClass('collapse');
}

function onlineServicesFromCollapse() {
    $('#online-services-list .list-group-title')
        .removeAttr('data-toggle href role aria-expanded aria-controls')
        .attr({
            'tabindex': "-1",
        });

    $('#online-services-list-collapsible').removeClass('collapse show');
}

function onlineServices(windowWidth) {
    if (lastWindowWidth >= 992 && windowWidth < 992) { // switch from lg to md
        onlineServicesToCollapse();
    } else if (lastWindowWidth < 992 && windowWidth >= 992) { // switch from md to lg
        onlineServicesFromCollapse();
    }
}

window.changeLocaleAndRestart = function(lang) {
    const iframe = document.getElementById('MB-ChatEntry').getElementsByClassName('chatbot_iframe')[0];
    iframe.contentWindow.postMessage({
        call : 'changeLocaleAndRestart',
        value: {locale: lang, originDomain: window && window.location && window.location.origin}
    }, chatServer);
};

const chatServer = $body.data('chat-server');
const lang = $('html').attr('lang');
const locales = {
    'fr': 'fr-FR',
    'nl': 'nl-NL',
    'en': 'en-US',
    'de': 'de-DE',
};
window.masterbotChatConfiguration = {
    chatServer: chatServer,
    // locale: locales[lang],
    originDomain: window && window.location && window.location.origin,
    haveMyOwnButton: true,
    fullScreen: false,
    context: {}
};

$(document).on('click', '.MB-ChatOpener', function() {
    $('.help').removeClass('show');
});

$(window).on('resize', resize);

$(document).ready(function() {
    if (lastWindowWidth >= 992) {
        onlineServicesFromCollapse();
    }
    if (lastWindowWidth < 992) {
        onlineServicesToCollapse();
        $('.search-filters-collapse-container').collapse('toggle');
    }
    if (lastWindowWidth < 768) {
        breadcrumbResponsive(lastWindowWidth, lastWindowWidth, true);
    }

    datepicker();
    resize();
    statsSwiper();
    cookiesBanner();
    lexicon();
    backToTop();
    externalLinks(jsTranslations);
    toc('#toc');
    gridMasonry();
    form();
    searchFilters();
    header();
    footer();
    jobsSwiper();
    jobsMap();
    selectToURL('.select-to-url');

    if ($onlineServices.length) {
        stickyOrNotSticky($onlineServices);
    }

    if (window.location.hash.length > 0) {
        scrollToElement($(window.location.hash));
    }
});
