import $ from "jquery";

export default function sameHeight(selector) {
    let $items = $(selector);
    let maxHeight = 0;

    $items.css('height', 'auto');

    $items.each(function() {
        let itemHeight = $(this).outerHeight();

        if (itemHeight > maxHeight) maxHeight = itemHeight;
    });

    $items.css('height', maxHeight);
}
