const $ = require("jquery");

export default function backToTop() {
    let $back2top = $('.back-to-top');
    $(window).on('scroll', function() {
        const newScrollTop = $(window).scrollTop();
        if (newScrollTop > 400 && !$back2top.hasClass('active')) {
            $back2top.addClass('active');
        } else if (newScrollTop <= 400 && $back2top.hasClass('active')) {
            $back2top.removeClass('active');
        }
    });
}
