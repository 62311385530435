const $ = require("jquery");

export default function breadcrumbResponsive(windowWidth, lastWindowWidth, firstPaint = false) {
    if ((firstPaint && windowWidth < 768) || (lastWindowWidth >= 768 && windowWidth < 768)) {
        $('.breadcrumb .breadcrumb-home').parent().remove();
    }

    if ($('.breadcrumb').is(':empty')) {
        $('.breadcrumb').parent().remove();
    }
}
