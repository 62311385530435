import sameHeight from "./sameHeight";

export default function footer() {
    function resize() {
        sameHeight('.footer-list-header');
    }

    resize();

    $(window).on('resize', resize);
}
