'use strict'
export function submitFormOnChange() {
    const forms = document.querySelectorAll('[data-submit-on-change]');

    forms.forEach(form => {
        form.querySelectorAll('select').forEach((select) => {
            select.onchange = (event) => {
                form.submit();
                event.preventDefault();
            };
        });
    });
}
