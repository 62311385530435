const $ = require("jquery");
import slugify from "./slugify";
import scrollToElement from "./scrollToElement";

export default function toc(selector = '#toc') {
    let $toc = $(selector);
    if ($toc.length && $toc.is(':empty')) {
        let $selectToc = $('#select-toc');
        let endOfContentReached = false;

        $('.content-wysiwyg h2:visible').each(function() {
            if ($(this).parents('.accordion').length !== 0) return;

            let text = slugify($(this).text());
            let $tocItem = $('<li class="toc-entry"><a href="#' + text + '">' + $(this).text() + '</a></li>');

            if (!endOfContentReached && $(this).hasClass('end-of-content-links')) {
                $tocItem.addClass('divider');
                endOfContentReached = true;
            }
            $toc.append($tocItem);

            if ($selectToc.length) {
                let $tocOption = $('<option value="' + text + '">' + $(this).text() + '</option>');
                $selectToc.append($tocOption);
            }

            $(this).attr('id', text);
        });
    }

    $(document).on('change', '#select-toc', function() {
        let anchor = $(this).val();

        scrollToElement($("#"+anchor));

        if (history.pushState) {
            history.pushState(null, null, "#"+anchor);
        } else {
            location.hash = "#"+anchor;
        }
    });

    $(document).on('click', '#toc a', function() {
        let anchor = $(this).attr('href');

        scrollToElement($(anchor));

        if (history.pushState) {
            history.pushState(null, null, anchor);
        } else {
            location.hash = anchor;
        }

        return false;
    });

    $(document).on('header-height-change', function (e) {
        if ($('.jobs-page').length === 0) return;

        let $header = $('#header');
        let newTop = 16 + $header.outerHeight() + e.detail;
        $('#toc').css('top', newTop);
    });
}
