import $ from "jquery";

export default function searchFilters() {
    $(document).on('click', '.search-filters .badge', function() {
        $(this).toggleClass('active');
    });

    $(document).on('change', '.criterion-select', function() {
        $('#criterion-submit').addClass('disabled').removeClass('pulse');
        let isLastChild = $(this).closest('.form-group').is(':last-child');
        if (!isLastChild) {
            $(this).closest('.form-group').nextAll().remove();
        }

        if ($(this).val() === 'placeholder') {
            return;
        }

        $.get(
            $('#criterion-form').data('url').replace('__CHANGEME__', $(this).val()),
            {},
            function(response) {
                if (response.type === 'info_sheet') {
                    $('#criterion-submit')
                        .attr('href', response.html)
                        .removeClass('disabled').addClass('pulse');
                } else {
                    $('#criterion-children').append(response.html);
                }
            }
        );
    });
}
