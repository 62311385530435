import Swiper from "swiper";
import {Navigation, Pagination} from 'swiper/modules';
import sameHeight from "./sameHeight";

export default function jobsSwiper() {
    let swiperJobs = new Swiper('.swiper-jobs', {
        modules: [Navigation, Pagination],
        slidesPerView: 3,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
        spaceBetween: 16,
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            480: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
            }
        }
    });

    function resize() {
        sameHeight('.swiper-jobs .card-title');
        sameHeight('.swiper-jobs .card');
    }

    resize();
    $(window).resize(resize);
}
